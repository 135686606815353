import React from "react";
import { useState, useEffect, useRef } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from "axios";
import PersonalDetails from "./personalDetails.component";
import Company from "./company.component";
import Bank from "./bank.component";
import AuthService from "../../services/auth.service";
import PaymentSummary from "./paymentSummary.component";
import DriversDocu from "./driversDocu.component";
import PerformanceData from "./performance.component";


const Dash = () => {
    const [ value, setValue ] = useState('1')
    const user = AuthService.getCurrentUser();
   
    //const api = new Client("SujDJW-9YkqykE4rXffRlA43323");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <div className="dashArch">
           {user?.username ?  <>
                <h3 className="h3TitlesCorrection">{user?.username}</h3>
                <Box sx={{ width: '100%', typography: 'body1', marginLeft: '2%', marginTop:'2%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable"
    scrollButtons="on" /*centered*/>
                            
                            <Tab label="Personal Details" value="1" />
                            <Tab label="Company / Tax Details" value="2" />
                            <Tab label="Bank Details" value="3" />
                            <Tab label="Payment Summary" value="4" />
                            <Tab label="Performance" value="5" />
                            <Tab label="Documents" value="6" />
                        </TabList>
                        </Box>
                            <TabPanel value="1">
                                <PersonalDetails />                
                            </TabPanel>
                            <TabPanel value="2">
                                <Company />                
                            </TabPanel>
                            <TabPanel value="3">
                                <Bank />                
                            </TabPanel>
                            <TabPanel value="4">
                                <PaymentSummary />              
                            </TabPanel>
                            <TabPanel value="5">
                                <PerformanceData />             
                            </TabPanel>
                            <TabPanel value="6">
                                <DriversDocu />                
                            </TabPanel>
                    </TabContext>
                </Box>  
            </> :
            <>
                <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
            </>
             }
        </div>
    )
}
export default Dash;