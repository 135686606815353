import React from "react";
import { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import BadgeMail from "./badge.component";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '10%', fontSize: 'x-large', paddingLeft:'1%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
      ...base,
      zIndex: 100,
      width: '100%',
      paddingLeft:'0%',
      marginLeft:'15px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? 'red' : 'white',
        color: 'rgb(97, 97, 97)',
        cursor: isDisabled ? 'not-allowed' : 'default',
       
        fontSize: 'x-large',
      };
    },
}

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.5)' }}
    >
      •
    </Box>
);

const departmentOptions = [
    {value: 'Senior Management', label: 'Senior Management'},
    {value: 'IT Support', label: 'IT Support'},
    {value: 'Recruitment', label: 'Recruitment'},
    {value: 'Tambor Fleet', label: 'Tambor Fleet'},
    {value: 'Tracfast', label: 'Tracfast'},
    {value: 'Regional Management', label: 'Regional Management'},
    {value: 'DME4-SKSO', label: 'DME4-SKSO'},
    {value: 'DRH1-SKSO', label: 'DRH1-SKSO'},
    {value: 'DXE1-SKSO', label: 'DXE1-SKSO'},
    {value: 'DRM4-SKSO', label: 'DRM4-SKSO'},
    {value: 'DSS2-SKSO', label: 'DSS2-SKSO'},
]
const departmentOptionsDriver = [
    {value: 'Senior Management', label: 'SKSO'},
    {value: 'Depot', label: 'Depot'},
    {value: 'Tambor Fleet - driver', label: 'Tambor Fleet'},
    {value: 'Tracfast', label: 'Tracfast'}
]

const smCat = [
    {value: 'Offboarding Request', label: 'Offboarding Request'},
    {value: 'Feedback/Driver complaints', label: 'Feedback/Driver complaints'}
]

const itCat = [
    {value: 'Website/access support', label: 'Website/access support'},
    {value: 'Email support', label: 'Email support'},
    {value: 'Planner/Schedule support', label: 'Planner/Schedule support'},
    {value: 'SKSO', label: 'SKSO'},
    {value: 'Driver access support', label: 'Driver access support'},
]

const recCat = [
    {value: 'Reschedule Interview', label: 'Reschedule Interview'},
    {value: 'Reschedule D&A', label: 'Reschedule D&A'},
    {value: 'Background check support', label: 'Background check support'},
    {value: 'Amazon app setup support', label: 'Amazon app setup support'},
    {value: 'Classroom Training', label: 'Classroom Training'},
    {value: 'Document Request', label: 'Document Request'},
]

const fleCat = [
    {value: 'Vehicle Breakdown', label: 'Vehicle Breakdown'},
    {value: 'Vehicle Service Required', label: 'Vehicle Service Required'},
    {value: 'Report Vehicle Fault', label: 'Report Vehicle Fault'},
    {value: 'Request Vehicle Return Date', label: 'Request Vehicle Return Date'},
    {value: 'Request Vehicle Collection', label: 'Request Vehicle Collection'},
    {value: 'Request Vehicle Exchange', label: 'Request Vehicle Exchange'},
    {value: 'Vehicle Inspections', label: 'Vehicle Inspections'},
    {value: 'Report Colision', label: 'Report Colision'},
    {value: 'Report Theft', label: 'Report Theft'},
    {value: 'Report Vehicle Damage', label: 'Report Vehicle Damage'},
    {value: 'Insurance Claims', label: 'Insurance Claims'},
    {value: 'Payment Query', label: 'Payment Query'},
    {value: 'PCN Query', label: 'PCN Query'},
    {value: 'AMZL Flex Portal', label: 'AMZL Flex Portal'},
]

const fleCatD = [
    {value: 'Vehicle Breakdown', label: 'Vehicle Breakdown'},
    {value: 'Vehicle Service Required', label: 'Vehicle Service Required'},
    {value: 'Report Vehicle Fault', label: 'Report Vehicle Fault'},
    {value: 'Request Vehicle Return Date', label: 'Request Vehicle Return Date'},
    {value: 'Request Vehicle Collection', label: 'Request Vehicle Collection'},
    {value: 'Request Vehicle Exchange', label: 'Request Vehicle Exchange'},
    {value: 'Vehicle Inspections', label: 'Vehicle Inspections'},
    {value: 'Report Colision', label: 'Report Colision'},
    {value: 'Report Theft', label: 'Report Theft'},
    {value: 'Report Vehicle Damage', label: 'Report Vehicle Damage'},
    {value: 'Insurance Claims', label: 'Insurance Claims'},
    {value: 'Payment Query', label: 'Payment Query'},
    {value: 'PCN Query', label: 'PCN Query'}
]

const traCat = [
    {value: 'Book Installation/Removal', label: 'Book Installation/Removal'},
    {value: 'Additional Support Required', label: 'Additional Support Required'},
    {value: 'Request Vehicle Location ', label: 'Request Vehicle Location '},
    {value: 'Report Vehicle theft', label: 'Report Vehicle theft'},
]

const PlaceTicket = () =>{
    const user = AuthService.getCurrentUser();
    const [ toDepartment, setToDepartment ] = useState()
    const [ cat, setCat ] = useState()
    const [ notes, setNotes ] = useState()
    const [ userTickets, setUserTickets ] = useState([])
    const [ userTicketsS, setUserTicketsS ] = useState([])
    const [ openFragment, setOpenFragment ] = useState(false)
    const [ category, setCategory ] = useState()
    const [ ticketId, setTicketId ] = useState()
    const [ status, setStatus ] = useState()
    const [ timestamp, setTimestamp ] = useState()
    const [ to, setTo ] = useState()
    const [ closedBy, setClosedBy ] = useState()
    const [ closedDate, setClosedDate ] = useState()
    const [ closing_note, setClosing_note ] = useState()
    const [ note, setNote ] = useState()
    const [ message, setMessage ] = useState()
    const [ messages, setMessages ]  = useState([])
    const [ interval, setInt ] = useState()
    const [ schedule, setSchedule ] = useState(false)
    const [ plMessage, setPlMessage ] = useState([])
    const [ update, setUpdate ] = useState(false)
    const [ dataD, setDataD] = useState([])
    const [ filter, setFilter ] = useState([false, false, true])
    const [ dataS, setDataS ] = useState([])
    const [ reg, setReg ] = useState()
    const [ regD, setRegD ] = useState()
    const [ logCheck, setLogCheck ] = useState(true)

    const messagesEndRef = useRef()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "instant", block: "end" })
    }
    useEffect(() => {
        //scrollToBottom()
        setSchedule(false)
      }, [messages]);

    const handleOpenFragment = (e, ticket_id, to_department, notes, timestamp, category, status, closed_by, closed_date, closing_note,reg) =>{
        setCategory(category)
        setTicketId(ticket_id)
        setStatus(status)
        setTimestamp(timestamp)
        setTo(to_department)
        setClosedBy(closed_by)
        setClosing_note(closing_note)
        setClosedDate(closed_date)
        setNote(notes)
        setRegD(reg)
        setOpenFragment(true)
        

        setInt(setInterval(() =>{
            setDataD([])
            setUpdate(false)
            UserService.getMessages({ticket_id: ticket_id}).then(
                response => {
                    console.log(response)
                    let data = response.data
                    data.map(mess =>{
                        if(user.username == mess.username){
                            setDataD(oldMessages =>[ ...oldMessages, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ '][You]: '+ mess.message}</h4>])
                        }else{
                            setDataD(oldMessages =>[ ...oldMessages, <h4>{'['+mess.createdAt?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+mess.createdAt?.split('T')[0].split('-')[0]+']['+mess.createdAt?.split('T')[1].split('.')[0]+ ']['+mess.username+']: '+ mess.message}</h4>])
                        }
                    })
                    UserService.markMessages({ticket_id: ticket_id, user: 'user'}).then(
                        response =>{
                            console.log(response)
                        }
                    )
                    if(data.length == 0){
                        setDataD(['No messages'])
                    }
                }
            ).catch(err => console.log(err))
        }, 1000))
    }

    useEffect(()=>{
        if(dataD.length >0 ){
            setMessages(dataD)
        }
    },[dataD])
    const handleCloseFragment = () =>{
        console.log(interval)
        clearInterval(interval)
        setOpenFragment(false)
        setMessages([])
    }

    useEffect(()=>{
        setUserTickets([])
        setUserTicketsS([])
        let data = []
        UserService.getUserTickets({username: user?.username}).then(
            response => {
                data = response.data
                setDataS(response.data)
                console.log(data)
                data.map((ele, index) =>{
                    //if(index <=3){
                        setUserTickets(oldUserTickets => [...oldUserTickets, 
                            <Card sx={{ minWidth: 250, maxWidth: 250,  maxHeight: 220 , marginLeft: 2, marginTop: 2}}>
                            <CardContent>
                                <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                    <ReceiptLongIcon /> Id: {'#'+ele.ticket_id}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    To: {ele.to_department}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    Status: {ele.status == 'Open' ? <><RadioButtonCheckedIcon style={{color: 'green'}}/>Ongoing</> : <><MotionPhotosOffIcon style={{color: 'red'}}/>Closed</>}
                                </Typography>
                            
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={e => handleOpenFragment(e, ele.ticket_id, ele.to_department, ele.notes, ele.timestamp, ele.category, ele.status, ele.closed_by, ele.closed_date, ele.closing_note, ele.reg)}>More details</Button>
                                <BadgeMail ticketId={ele.ticket_id} dep={"user"} update={update}/>
                            </CardActions>
                        </Card>
                        
                        ])
                    /*}else{
                        setUserTicketsS(oldUserTicketsS => [...oldUserTicketsS, 
                            <Card sx={{ minWidth: 250, maxWidth: 250,  maxHeight: 220 , marginLeft: 2, marginTop: 2}}>
                            <CardContent>
                                <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                    <ReceiptLongIcon />  Id: {'#'+ele.ticket_id}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    To: {ele.to_department}
                                </Typography>
                                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                    Status: {ele.status == 'Open' ? 'Ongoing' : 'Closed'}
                                </Typography>
                            
                            </CardContent>
                            <CardActions>
                                <Button size="small">More details</Button>
                                <Badge badgeContent={2} color="primary">
                                    <MailIcon color="action" />
                                </Badge>
                            </CardActions>
                        </Card>
                        
                        ])
                    }*/
                })
               
            }
            
        ).catch(err =>{
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])
    
    const handleDepartment = (e) =>{
        setToDepartment('')
        setToDepartment(e.value)
        setCat('')
        setNotes('')
    }

    const handleSubmit = () =>{
        let id = new Date().getTime()
        let data = {ticket_id: id, username: user.username, department: user.department, to_department: toDepartment == 'Tambor Fleet - driver' ? 'Tambor Fleet' : toDepartment, category: cat || null, reg: reg || null, notes: notes || null, status: 'Open'}
        UserService.postTicket(data).then(
            response =>{
                console.log(response)
                window.location.reload(false)
            }
        ).catch(err => console.log(err))
    }

    
    const handleSendMessage = () =>{
        let data = {ticket_id: ticketId, username: user.username, message: message, seen_user: 'Seen', seen_dep: 'Unseen', seen_user_list: user.username}
        UserService.sendMessage(data).then(
            response => {
                console.log(response)
                setMessage('')
                setSchedule(true)
            }
        ).catch(err => console.log(err))
    }
    const handleFilter = (e, filter) =>{
        setUserTickets([])
        if(filter == 'Open'){
            setFilter([true, false, false])
            dataS.map(ele =>{
                if(ele.status == 'Open'){
                    setUserTickets(oldUserTickets =>[...oldUserTickets,
                        <Card sx={{ minWidth: 250, maxWidth: 250,  maxHeight: 220 , marginLeft: 2, marginTop: 2}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                <ReceiptLongIcon /> Id: {'#'+ele.ticket_id}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                To: {ele.to_department}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                Status: {ele.status == 'Open' ? <><RadioButtonCheckedIcon style={{color: 'green'}}/>Ongoing</> : <><MotionPhotosOffIcon style={{color: 'red'}}/>Closed</>}
                            </Typography>
                        
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={e => handleOpenFragment(e, ele.ticket_id, ele.to_department, ele.notes, ele.timestamp, ele.category, ele.status, ele.closed_by, ele.closed_date, ele.closing_note, ele.reg)}>More details</Button>
                            <BadgeMail ticketId={ele.ticket_id} dep={"user"} update={update}/>
                        </CardActions>
                    </Card>])
                }
            })
        }else if(filter == 'Closed'){
            setFilter([false, true, false])
            dataS.map(ele =>{
                if(ele.status == 'Closed'){
                    setUserTickets(oldUserTickets =>[...oldUserTickets,
                        <Card sx={{ minWidth: 250, maxWidth: 250,  maxHeight: 220 , marginLeft: 2, marginTop: 2}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                <ReceiptLongIcon /> Id: {'#'+ele.ticket_id}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                To: {ele.to_department}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                Status: {ele.status == 'Open' ? <><RadioButtonCheckedIcon style={{color: 'green'}}/>Ongoing</> : <><MotionPhotosOffIcon style={{color: 'red'}}/>Closed</>}
                            </Typography>
                        
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={e => handleOpenFragment(e, ele.ticket_id, ele.to_department, ele.notes, ele.timestamp, ele.category, ele.status, ele.closed_by, ele.closed_date, ele.closing_note, ele.reg)}>More details</Button>
                            <BadgeMail ticketId={ele.ticket_id} dep={"user"} update={update}/>
                        </CardActions>
                    </Card>])
                }
            })
        }else{
            setFilter([false, false, true])
            dataS.map(ele =>{
                    setUserTickets(oldUserTickets =>[...oldUserTickets,
                        <Card sx={{ minWidth: 250, maxWidth: 250,  maxHeight: 220 , marginLeft: 2, marginTop: 2}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="text.secondary" gutterBottom>
                                <ReceiptLongIcon /> Id: {'#'+ele.ticket_id}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                To: {ele.to_department}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                Status: {ele.status == 'Open' ? <><RadioButtonCheckedIcon style={{color: 'green'}}/>Ongoing</> : <><MotionPhotosOffIcon style={{color: 'red'}}/>Closed</>}
                            </Typography>
                        
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={e => handleOpenFragment(e, ele.ticket_id, ele.to_department, ele.notes, ele.timestamp, ele.category, ele.status, ele.closed_by, ele.closed_date, ele.closing_note, ele.reg)}>More details</Button>
                            <BadgeMail ticketId={ele.ticket_id} dep={"user"} update={update}/>
                        </CardActions>
                    </Card>])
            })
        }
    }
    return(
        <div className="incidentArch">
            {logCheck ? 
            <>
                <h3 className="h3TitlesCorrection">Tickets</h3>
                <div className="ownTickets">
                    {filter[0]? <button className="buttonX" style={{backgroundColor: 'gray'}}>Open<FilterListOffIcon /></button> : <button className="buttonX" onClick={e => handleFilter(e, 'Open')}>Open<FilterListIcon /></button>}{filter[1] ?<button className="buttonX" style={{backgroundColor: 'gray'}}>Closed<FilterListOffIcon /></button>: <button className="buttonX" onClick={e => handleFilter(e, 'Closed')}>Closed<FilterListIcon /></button>}{filter[2] ? <button className="buttonX" style={{backgroundColor: 'gray'}}>All<FilterListOffIcon /></button> : <button className="buttonX" onClick={e => handleFilter(e, 'All')}>All<FilterListIcon /></button>}
                    <div className="userFlex">
                        {userTickets}
                    </div>
                    <div className="userFlex" style={{marginTop:'10px'}}>
                        {userTicketsS}
                    </div>
                
                </div>
                <div className="place">
                    <header>
                        <h3 className="title">Place a ticket</h3>
                    </header>
                    <h4 className="subtt">To Department:</h4>
                    <div className="inlineLnx">
                    <Select 
                        options={user?.department?.match('Driver') ? departmentOptionsDriver : departmentOptions}
                        styles={colourStyles}
                        onChange={e => handleDepartment(e)}
                        />
                    </div>
                    {toDepartment && toDepartment !== 'Regional Management' && toDepartment !== 'DME4' && toDepartment !== 'DRM4' && toDepartment !== 'DRH1' && toDepartment !== 'Depot' ?
                        <>
                            <h3 className="subtt">Category:</h3>
                            <div className="inlineLnx">
                                <Select 
                                    required
                                    options={toDepartment == 'Senior Management' ? smCat : toDepartment == 'SKSO' ? smCat : toDepartment == 'IT Support' ? itCat : toDepartment == 'Recruitment' ? recCat : toDepartment == 'Tambor Fleet' ? fleCat : toDepartment == 'Tambor Fleet - driver' ? fleCatD : traCat}
                                    styles={colourStyles}
                                    value={{ id: cat, label: cat }}
                                    onChange={e => setCat(e.value)}
                                />
                            </div>
                            {toDepartment == 'Tambor Fleet' || toDepartment == 'Tambor Fleet - driver' || toDepartment == 'Tracfast' ?
                                <>
                                    <h3 className="subtt">Reg:</h3>
                                    <div className="inlineLnx">
                                        <input style={{width: '100%', fontSize: 'x-large'}} onChange={e => setReg(e.target.value?.toUpperCase())} value={reg}></input>
                                    </div>
                                </>:''
                            }
                            <h3 className="subtt">Notes:</h3>
                            <div className="inlineLnx">
                                <textarea rows={10} style={{width: '100%', fontSize: 'large'}} onChange={e => setNotes(e.target.value)}></textarea>
                            </div>
                        </>:
                        <>
                            <h4 className="subtt">Please write the question</h4>
                            <div className="inlineLnx">
                                <textarea rows={10} style={{width: '100%', fontSize: 'large'}} onChange={e => setNotes(e.target.value)}></textarea>
                            </div>
                        </>
                    }
                    {(notes || cat) && toDepartment ?
                        <button style={{width: '100%', borderRadius: '5px', backgroundColor: 'rgb(219, 248, 253)', marginTop: '50px', fontSize: 'x-large'}} onClick={handleSubmit}>Submit Ticket</button> :''
                    }
                </div>
                <React.Fragment >
                    <Dialog
                        fullScreen
                        open={openFragment}
                        TransitionComponent={Transition}
                        keepMounted
                        //onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{width: '100%'}}
                    >
                        <DialogTitle style={{background: '#E8E8E8'}}>
                        <h2 className="title">Ticket No: {ticketId}</h2>
                        
                        </DialogTitle >
                        <DialogContent style={{background: '#E8E8E8'}}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="ticketCont">
                                <div className="ticketDet">
                                    <h3 className="title"><b>Details</b></h3>
                                    <h4><b>Status: </b>{status}</h4>
                                    <h4><b>To department: </b>{to  == 'Tambor Fleet - driver' ? to.split('-')[0] : to}</h4>
                                    <h4><b>Date created: </b>{timestamp?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+timestamp?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+timestamp?.split('T')[0].split('-')[0]}</h4>
                                    <h4><b>Reason: </b>{category}</h4>
                                    <h4>{to == 'Tambor Fleet - driver' || to == 'Tambor Fleet' || to == 'Tracfast' ? <b>Reg: </b> : ''}{to == 'Tambor Fleet - driver' || to == 'Tambor Fleet' || to == 'Tracfast' ? regD : ''}</h4>
                                    <h4><b>Notes: </b>{note}</h4>
                                    <h4>{status == 'Closed'? <b>Closed by: </b>: ''}{status == 'Closed'? closedBy : ''}</h4>
                                    <h4>{status == 'Closed'? <b>Closed Date: </b>  : ''}{status == 'Closed'? closedDate?.split('T')[0].split('-')[2].toString().padStart(2, "0")+'-'+closedDate?.split('T')[0].split('-')[1].toString().padStart(2, "0")+'-'+closedDate?.split('T')[0].split('-')[0] : ''}</h4>
                                    <h4>{status == 'Closed'? <b>Closing Note: </b> : ''}{status == 'Closed'? closing_note : ''}</h4>
                                </div>
                                <div className="ticketChat">
                                    <div className="chatWind">
                                            {messages.length ==  0 ? 
                                                <div className="loadingMess">
                                                    <Box sx={{ display: 'flex', textAlign: 'center' }}>
                                                        <CircularProgress />
                                                    </Box>Retriving messages...
                                                </div> : 
                                                messages[0] == 'No messages' ? 
                                                'No Messages to display' : messages 
                                                                    }
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <div className="flex">
                                        {status == 'Open' ? <input type="text" className="chatInp" onChange={e => setMessage(e.target.value)} value={message}></input> : <input type="text" className="chatInp" onChange={e => setMessage(e.target.value)} value={message} disabled></input>}
                                        {status == 'Open' ? !schedule ? <SendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: '#27C5D9'}} onClick={handleSendMessage}/> : <ScheduleSendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: '#27C5D9'}}/> : <SendIcon sx={{fontSize: '50px', marginTop: '5%', marginLeft: '2%', color: 'gray'}} />}
                                    </div>
                                </div>
                        </div>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{background: '#E8E8E8'}}>
                            <Button onClick={handleCloseFragment} >Close</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </>:
            <>
                <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
            </>
            
            }
        </div>
    )
}
export default PlaceTicket;