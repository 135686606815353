import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSearchParams } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Button from '@mui/material/Button';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));





const AmzlMileage = () =>{
    const [ data, setData ] = useState()
    const [ displayData, setDisplayData] = useState([])
    const [ weekNo, setWeekNo ] = useState()
    const [ rate, setRate ] = useState()
    const [ notes, setNotes ] = useState()
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ entry, setEntry ] = useState()
    const [ warningWeekNo, setWarningWeekNo ] = useState()
    const [ warningRate, setWarningRate ] = useState()
    const [ openDel, setOpenDel ] = useState(false)
    const [ openEdit, setOpenEdit ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/*<GridToolbarExport />*/}
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => handleOpenAdd(e)}> 
                    <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(()=>{
        setLoading(true)
        UserService.getAmzlMileage().then(
            response =>{
                setData(response.data)
                setLoading(false)
            }
        ).catch(err => console.log(err.message))
    },[])
    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'week_no',
            headerName: 'Week No',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'rate',
            headerName: 'Rate',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 273,
            editable: false,
        },
        {
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleOpenEdit(param.row)} />
                    </>
            )
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                    </>
            )
        }
    ]
    useEffect(()=>{
        let dataPlc = []
        data?.map(row =>{
            dataPlc.push({id: row.entry, week_no: row.week_no, rate: row.rate, notes: row.notes})
        })
        dataPlc.reverse()
        setDisplayData(dataPlc)
    },[data])

    const handleSearch = (e) =>{
        let dataPlc = []
        data?.map(row =>{
            if(row.week_no?.match(e.target.value)){
                dataPlc.push({id: row.entry, week_no: row.week_no, rate: row.rate, notes: row.notes})
            }
        })
        dataPlc.reverse()
        setDisplayData(dataPlc)
    }
    const handleAddClose = () =>{
        setOpenAdd(false)
    }
    const handleOpenEdit = (row) =>{
        setEntry(row.id)
        setWeekNo(row.week_no)
        setRate(row.rate)
        setNotes(row.notes)
        setOpenEdit(true)
    }

    const handleOpenAdd = (e) =>{
        setWeekNo("")
        setRate("")
        setNotes("")
        setOpenAdd(true)
    }

    useEffect(() =>{
        if(weekNo?.length == 7){
            setWarningWeekNo("")
        }
        if(rate){
            setWarningRate("")
        }
    }, [weekNo, rate])

    const handleWeekNo = (week) =>{
        if(week.length == 4){
            setWeekNo(week+'-')
        }else{
            if(week.length <= 7){
                setWeekNo(week)
            }
        }
    }

    const deleteRow = (row) =>{
        setEntry(row.id)
        setRate(row.rate)
        setWeekNo(row.week_no)
        setNotes(row.notes)
        setOpenDel(true)
    }

    const add = () =>{
        if(rate && weekNo.length == 7){
            let dataSend = {week_no: weekNo, rate: rate, notes: notes ? notes : ''}
            UserService.insertAmzlMileage(dataSend).then(
                response =>{
                    console.log(response)
                    window.location.reload(false)
                }
            ).catch(err => console.log(err.message))

        }else{
            if(!rate){
                setWarningRate("field required")
            }
            if(weekNo || weekNo.length < 7){
                setWarningWeekNo("field missing or too short")
            }
        }
    }
    const handleDelClose = () =>{
        setOpenDel(false)
    }
    const deleteRowSend = () => {
        let dataSend = {id: entry}
        UserService.deleteAmzlMileage(dataSend).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    const handleCloseEdit = () =>{
        setWeekNo("")
        setRate("")
        setNotes("")    
        setOpenEdit(false)
    }

    const update = () =>{
        if(rate && weekNo.length == 7){
            let dataSend = {id: entry, week_no: weekNo, rate: rate, notes: notes ? notes : ''}
            UserService.updateAmzlMileage(dataSend).then(
                response =>{
                    console.log(response)
                    window.location.reload(false)
                }
            ).catch(err => console.log(err.message))

        }else{
            if(!rate){
                setWarningRate("field required")
            }
            if(weekNo || weekNo.length < 7){
                setWarningWeekNo("field missing or too short")
            }
        }
    }
    
    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Amzl Mileage</h3>
            {/*<div className="searchBar1 fixed1 " >
            
                <button class="buttonSkOn" style={{marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenAdd(e)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>New entry</span>
                    <div class="fill-container"></div>
                </button>
            </div>*/  }    
            <Box sx={{ height: 3100, width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    loading={loading}
                    {...data}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    onRowClick={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={{
                        id: false
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openAdd}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add Line</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Week No</label>
                                    <input type="text" name="dlNo" className="lone" value={weekNo} onChange={e => handleWeekNo(e.target.value)} placeholder="yyyy-ww"></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningWeekNo ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningWeekNo}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Rate</label>
                                    <input type="number" name="dlNo" className="lone" value={rate} onChange={e => setRate(e.target.value)} placeholder="0.000"></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningRate ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningRate}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea type="text" name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={add}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleAddClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the below?</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Week No: {weekNo}</label>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Rate: {rate}</label>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes: {notes}</label>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteRowSend}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleDelClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openEdit}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add Line</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Week No</label>
                                    <input type="text" name="dlNo" className="lone" value={weekNo} onChange={e => handleWeekNo(e.target.value)} placeholder="yyyy-ww"></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningWeekNo ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningWeekNo}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Rate</label>
                                    <input type="number" name="dlNo" className="lone" value={rate} onChange={e => setRate(e.target.value)} placeholder="0.000"></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningRate ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningRate}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea type="text" name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={update}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseEdit}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default AmzlMileage;