const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'metric',
        headerName: 'Metric',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
      },
    {
        field: 'value',
        headerName: 'Value',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
      },
      {
        field: 'target',
        headerName: 'Target',
        headerClassName: 'table-header',
        width: 80,
        editable: false,
      }
]
export default columns;