const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'sunday',
        headerName: 'Sunday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'monday',
        headerName: 'Monday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'tuesday',
        headerName: 'Tuesday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'wednesday',
        headerName: 'Wednesday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'thursday',
        headerName: 'Thursday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'friday',
        headerName: 'Friday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'saturday',
        headerName: 'Saturday',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 120,
        editable: false,
    }

]
export default columns;