import React from 'react';
import { useState, useEffect } from 'react';
import UserService from "../../services/user.service";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Select from 'react-select';
import AuthService from '../../services/auth.service';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTabContext } from '@material-ui/lab/TabContext';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Button from '@mui/material/Button';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));


const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const typeOptions = [
    {value: 'Balance', label: 'Balance'},
    {value: 'Payment', label: 'Payment'}
]

const statusOption = [
    {value: 'Active', label: 'Active'},
    {value: 'Completed', label: 'Completed'}
]


const Debtors = () =>{
    const [ data, setData ] = useState()
    const [ displayData, setDisplayData ] = useState([])
    const [ entry, setEntry ] = useState()
    const [ name, setName ] = useState()
    const [ sko_no, setSko_no ] = useState()
    const [ type, setType ] = useState()
    const [ document_no, setDocument_no ] = useState()
    const [ amount, setAmount ] = useState()
    const [ date, setDate ] = useState()
    const [ status, setStatus ] = useState()
    const [ payment_from, setPayment_from ] = useState()
    const [ payment_link, setPayment_link ] = useState()
    const [ notes, setNotes ] = useState()
    const [ openEdit, setOpenEdit ] = useState(false)
    const [ dateOpen, setDateOpen ] = useState(false)
    const user = AuthService.getCurrentUser();
    const [ errStatus, setErrStatus ] = useState(false)
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ nameOptions, setNameOptions ] = useState()
    const [ nameData, setNameData ] = useState()
    const [ openDel, setOpenDel ] = useState(false)
    const [ deleteId, setDeleteId ] = useState()
    const [ loading, setLoading ] = useState(false)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/*<GridToolbarExport />*/}
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => setOpenAdd(true)}> 
                    <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                </Button>
            </GridToolbarContainer>
        );
    }
    

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'sko_no',
            headerName: 'SK No',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Type',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'document_no',
            headerName: 'Document No',
            headerClassName: 'table-header',
            width: 200,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Date',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
            type: 'date',
            valueFormatter: params => {
                return params ? new Date(params).toLocaleDateString('en-GB') : ''
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
        },
        {
            field: 'payment_from',
            headerName: 'Payment From',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
        },
        {
            field: 'payment_link',
            headerName: 'Link',
            headerClassName: 'table-header',
            width: 150,
            editable: false,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerClassName: 'table-header',
            width: 350,
            editable: false,
        },
        {
            field: 'edit',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <EditIcon onClick={e => handleParams(param.row)} />
                    </>
            )
        },
        {
            field: 'delete',
            headerName: '',
            headerClassName: 'table-header',
            width: 50,
            editable: false,
            renderCell: (param) =>(
                    <>
                        <DeleteOutlineIcon onClick={e => deleteRow(param.row)} />
                    </>
            )
        }
    ]

    useEffect(()=>{
        setLoading(true)
        UserService.getDebtors().then(
            response =>{
                console.log(response.data)
                setData(response.data)
                UserService.getContactNames().then(
                    response =>{
                        UserService.getArchiveNames().then(
                            response1 =>{
                                let dataPl = []
                                let prevData = []
                                const dataCd = response.data
                                console.log(dataCd)
                                dataCd?.map(row =>{
                                    dataPl.push({value: row.name, label: row.name})
                                    prevData?.push(row)
                                })
                                const currData = response1.data
                                currData.map(row =>{
                                    prevData?.push(row)
                                    dataPl?.push({value: row.name, label: row.name})
                                })
                                setNameData(prevData)
                                setNameOptions(dataPl)
                                setLoading(false)
                            }
                        )
                    }
                )
            }
        ).catch(err => console.log(err))
        
        
    },[])
    useEffect(()=>{
        let dataPl = []
        data?.map(row =>{
            dataPl.push({
                id: row.entry, 
                name: row.name,
                sko_no: row.sko_no,
                type: row.type,
                description: row.description,
                document_no: row.document_no,
                amount: row.amount,
                date: row.date,
                status: row.status,
                payment_from: row.payment_from,
                payment_link: row.payment_link,
                notes: row.notes
            })
        })
        setDisplayData(dataPl)
    },[data])

    const handleCellEdit = (params) =>{
        console.log(params)
    }


    const handleParams = (params) =>{
        console.log(params)
        setEntry(params.id)
        setName(params.name)
        setSko_no(params.sko_no)
        setType(params.type)
        setDocument_no(params.document_no)
        setAmount(params.amount)
        setDate(params.date)
        setStatus(params.status)
        setPayment_from(params.payment_from)
        setPayment_link(params.payment_link)
        setNotes(params.notes)
        setOpenEdit(true)
    }

    const handleEditClose = () =>{
        setEntry('')
        setName('')
        setSko_no('')
        setType('')
        setDocument_no('')
        setAmount('')
        setDate('')
        setStatus('')
        setPayment_from('')
        setPayment_link('')
        setNotes('')
        setOpenEdit(false)
    }
    const dateChange = (e) =>{
        setDate(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }

    const update = () =>{
        let dataSend = {
            entry: entry,
            name: name ? name : null,
            sko_no: sko_no ? sko_no : null,
            type: type ? type : null,
            document_no: document_no ? document_no : null,
            amount: amount ? Number(amount)?.toFixed(2) : null,
            date: date ? date : null,
            status: status ? status : null,
            payment_from: payment_from ? payment_from : null,
            payment_link: payment_link ? payment_link : null,
            notes: notes ? notes :null,
            updatedBy: user ? user.username : null
        }
        console.log(dataSend)
        UserService.updateDebtors(dataSend).then(
            response =>{
                let data = displayData
                displayData?.map((row, index) =>{
                    if(entry == row.id){
                        console.log(row)
                        data[index].id = entry
                        data[index].name = name
                        data[index].sko_no = sko_no
                        data[index].type = type
                        data[index].document_no = document_no
                        data[index].amount = amount
                        data[index].date = date
                        data[index].status = status
                        data[index].payment_from = payment_from
                        data[index].payment_link = payment_link
                        data[index].notes = notes
                        }
                        console.log(row)
                    })
                
                
                console.log(data)
                setDisplayData(data)
                setEntry('')
                setName('')
                setSko_no('')
                setType('')
                setDocument_no('')
                setAmount('')
                setDate('')
                setStatus('')
                setPayment_from('')
                setPayment_link('')
                setNotes('')
                setErrStatus(false)
                setOpenEdit(false)
                //window.location.reload(false)
                
            }
        ).catch(err => {
            setErrStatus(true)
            console.log(err)
        })
    }
    
    const submit = () =>{
        let dataSend = {
            name: name ? name : null,
            sko_no: sko_no ? sko_no : null,
            type: type ? type : null,
            document_no: document_no ? document_no : null,
            amount: amount ? Number(amount)?.toFixed(2) : null,
            date: date ? date : null,
            status: status ? status : null,
            payment_from: payment_from ? payment_from : null,
            payment_link: payment_link ? payment_link : null,
            notes: notes ? notes :null,
            updatedBy: user ? user.username : null
        }
        UserService.createDebtors(dataSend).then(
            response =>{
                setEntry('')
                setName('')
                setSko_no('')
                setType('')
                setDocument_no('')
                setAmount('')
                setDate('')
                setStatus('')
                setPayment_from('')
                setPayment_link('')
                setNotes('')
                setErrStatus(false)
                window.location.reload(false)
            }
        ).catch(err => console.log(err))
    }
    
    const handleCloseAdd = () =>{
        setEntry('')
        setName('')
        setSko_no('')
        setType('')
        setDocument_no('')
        setAmount('')
        setDate('')
        setStatus('')
        setPayment_from('')
        setPayment_link('')
        setNotes('')
        setOpenAdd(false)
    } 

    const handleName = (name) =>{
        setName(name)
        nameData?.map(row =>{
            if(name == row.name){
                setSko_no(row.account_no)
            }
        })
    }

    const deleteRow = (row) =>{
        setOpenDel(true)
        console.log(row)
        setDeleteId(row.id)
    }

    const deleteRowAccept = () =>{
        UserService.deleteDebtor({entry: deleteId}).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Debtors data</h3>
            {/*<div className="searchBar1 fixed1 " >
                <button class="buttonSkOn" style={{marginLeft: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenAdd(true)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>New entry</span>
                    <div class="fill-container"></div>
                </button>
            </div>*/}
                
            
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //loading={loading}
                    {...data}
                    processRowUpdate={params =>handleCellEdit(params)}
                    onRowClick={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={{
                        id: false
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openEdit}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Edit: {name}</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Name</label>
                                    <input type="text" name="dlNo" className="lone" value={name} onChange={e => setName(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">SK No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={sko_no} onChange={e => setSko_no(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Type</label>
                                    <Select 
                                        options={typeOptions}
                                        styles={colourStyles}
                                        value={{label: type, value: type}}
                                        onChange={e => setType(e.value)}
                                    /> 
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Document No</label>
                                    <input type="text" name="dlNo" className="lone" value={document_no} onChange={e => setDocument_no(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Amount</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={amount} onChange={e => setAmount(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Date</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={date} onClick={e => setDateOpen(true)} onChange={e => setDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                </div>
                                <div className="flexComp ">
                                    <div className='calendarL'>
                                        {dateOpen == true ?
                                                <>
                                                    <Calendar  
                                                        onChange={e => {
                                                            dateChange(e)
                                                            setDateOpen(false)
                                                        }} 
                                                        style={{marginLeft: '200px'}}
                                                        value={date && date !== '0000-00-00' && date !== 'Null' ? new Date(date): new Date()} 
                                                        validRange = {{
                                                            start: new Date(),
                                                            end: null
                                                        }}
                                                        
                                                        className={'calendarX'}
                                                    />
                                                    {/*<CloseRoundedIcon onClick={e => setDateOpen(false)}>Close</CloseRoundedIcon>*/}
                                                </> 
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Status</label>
                                    <Select 
                                        options={statusOption}
                                        styles={colourStyles}
                                        value={{label: status, value: status}}
                                        onChange={e => setStatus(e.value)}
                                    /> 
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Link</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={payment_link} onChange={e => setPayment_link(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Notes</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={notes}  onChange={e => setNotes(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Payment From</label>
                                    <input type="text" name="dlNo" className="lone" value={payment_from} onChange={e => setPayment_from(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={update}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleEditClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>  
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openAdd}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Add: {name}</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Name</label>
                                    <Select 
                                        options={nameOptions}
                                        styles={colourStyles}
                                        value={{label: name, value: name}}
                                        onChange={e => handleName(e.value)}
                                    /> 
                                    
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">SK No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={sko_no} onChange={e => setSko_no(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Type</label>
                                    <Select 
                                        options={typeOptions}
                                        styles={colourStyles}
                                        value={{label: type, value: type}}
                                        onChange={e => setType(e.value)}
                                    /> 
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Document No</label>
                                    <input type="text" name="dlNo" className="lone" value={document_no} onChange={e => setDocument_no(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Amount</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={amount} onChange={e => setAmount(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Date</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={date} onClick={e => setDateOpen(true)} onChange={e => setDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                </div>
                                <div className="flexComp ">
                                    <div className='calendarL'>
                                        {dateOpen == true ?
                                                <>
                                                    <Calendar  
                                                        onChange={e => {
                                                            dateChange(e)
                                                            setDateOpen(false)
                                                        }} 
                                                        style={{marginLeft: '200px'}}
                                                        value={date && date !== '0000-00-00' && date !== 'Null' ? new Date(date): new Date()} 
                                                        validRange = {{
                                                            start: new Date(),
                                                            end: null
                                                        }}
                                                        
                                                        className={'calendarX'}
                                                    />
                                                    {/*<CloseRoundedIcon onClick={e => setDateOpen(false)}>Close</CloseRoundedIcon>*/}
                                                </> 
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Status</label>
                                    <Select 
                                        options={statusOption}
                                        styles={colourStyles}
                                        value={{label: status, value: status}}
                                        onChange={e => setStatus(e.value)}
                                    /> 
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Link</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={payment_link} onChange={e => setPayment_link(e.target.value)}></input>
                                </div>
                                <div className="flexComp">
                                    <label for="origin" className="label2 correction">Notes</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={notes}  onChange={e => setNotes(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Payment From</label>
                                    <input type="text" name="dlNo" className="lone" value={payment_from} onChange={e => setPayment_from(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={submit}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Submit</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseAdd}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the line?</label></DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteRowAccept}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenDel(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default Debtors;