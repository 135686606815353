import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import columns from "./debtorsSum";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));



const DebtorsSummary = () =>{
    const [ displayData, setDisplayData ] = useState([])
    const [ debtors, setDebtors ] = useState([])
    const [ debtorData, setDebtorData ] = useState([])
    const [ start, setStart ] = useState(0)
    const [ end, setEnd ] = useState(0)
    const [ made, setMade ] = useState(0)
    const [ selectedIds, setSelectedIds ] = useState([])
    const [ agreeRows, setAgreeRows ] = useState([])
    const [ openInv, setOpenInv ] = useState(false)
    const [ invoiceCheck, setInvoiceCheck ] = useState(false)
    const [ invoiceMessage, setInvoiceMessage ] = useState("")


    function CustomToolbar(){
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/*<GridToolbarExport />*/}
                {selectedIds.length > 0 ? 
                    <Button className="incGridButton" onClick={e => handleOpen(e)}>
                        <NoteAddOutlinedIcon fontSize="small" />DEBT SEND
                    </Button> :
                    <Button className="incGridButton" style={{color: 'gray'}}>
                        <NoteAddOutlinedIcon fontSize="small" />DEBT SEND
                    </Button>
                }
                <GridToolbarQuickFilter />
                <p className="pInsideTable">Total starting debt: -£{start.toString().substring(1, start.toString().length)}</p>
                <p className="pInsideTable">Total payments made: £{made}</p>
                <p className="pInsideTable">Total balance: -£{end.toString().substring(1, end.toString().length)}</p>
            </GridToolbarContainer>
        );
    }

    useEffect(() =>{
        UserService.getDebtorsAssociates().then(
            response =>{
                setDebtors(response.data)
            }
        ).catch(err => console.log(err))
        UserService.getDebtors().then(
            response =>{
                setDebtorData(response.data)
            }
        ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
        if(debtorData.length > 0 && debtors.length > 0){
            let data = []
            let id = 0
            let totalStart = 0
            let totalEnd = 0
            let totalMade = 0
            debtors?.map(debtor =>{
                let lastPay = ''
                let debtStart = 0
                let paymentsDone = 0
                let totalDone = 0
                let balance = 0
                id += 1
                debtorData?.forEach(debDat =>{
                    if(debtor.name == debDat.name){
                        if(debDat.type == 'Balance' || debDat.type.match('FL')){
                            debtStart += Number(debDat.amount)
                        }
                        if(debDat.type == 'Payment'){
                            paymentsDone += 1
                            lastPay = debDat.date
                            totalDone += Number(debDat.amount)
                        }
                    }
                })
                balance = debtStart + totalDone
                totalStart += debtStart
                totalEnd += balance
                totalMade += totalDone
                data.push({id: id, name: debtor.name, sk_no: debtor.account_no, last_payment: lastPay, debt_start: '-£'+debtStart.toString().substring(1, debtStart.toString().length), payments_count: paymentsDone, total_payments: '£'+totalDone, balance: '-£'+balance.toString().substring(1, balance.toString().length)})
                
            })
            setStart(totalStart.toFixed(2))
            setEnd(totalEnd.toFixed(2))
            setMade(totalMade.toFixed(2))
            setDisplayData(data)
        }
    },[debtorData, debtors])

    const handleOpen = (e) =>{
        let data = []
        selectedIds?.forEach(item =>{
          displayData?.map((row, index) =>{
            if(item == row.id){
              console.log(row)
              data.push({id: index, sk_no: row.sko_no , name: row.name})
            }
          })
        })
        setAgreeRows(data)
        setOpenInv(true)
    }
    const handleClose = () =>{
        setOpenInv(false)
        setInvoiceMessage('')
    }
    const createInvoices = () =>{ 
        setInvoiceCheck(true)
        let invoiceData = [] 
        agreeRows?.map(row =>{
          debtorData.map(item =>{
            if(row.name == item.name){
                console.log(row)
                invoiceData.push(item)
            }
          })
        })
        console.log(invoiceData)
        UserService.createDebtorsPDF(invoiceData).then(
            response =>{
              if(response.data == 'ok'){
                setInvoiceMessage('Invoices created.')
                console.log(response)
                setInvoiceCheck(false)
              }else{
                setInvoiceMessage('Something went wrong.')
                setInvoiceCheck(false)
              }
            }
        ).catch(err => {
            setInvoiceMessage('Something went wrong.')
            console.log(err)
            setInvoiceCheck(false)
        })
      } 
    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Debtors summary</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    checkboxSelection
                    pagination
                    rows={displayData}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //loading={loading}
                    {...displayData}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    //onRowClick={params => console.log(params)}
                    onRowSelectionModelChange={e => setSelectedIds(e)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={{
                        id: false
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openInv}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >{invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ? '' : 'Are you sure you want to create outstanding invoices for the selected lines?'}</label></DialogTitle>
                    <DialogContent>
                    {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ? 
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlNo" className="label2">{invoiceMessage}</label>
                            </div>
                        </div>:
                    invoiceCheck ?
                        <div className="flex spaceing" style={{marginTop: '60px'}}>
                            <div className="flexComp">
                                <CircularProgress color="inherit" style={{marginLeft: '45%'}}/>
                                <label for="dlNo" className="label2" style={{marginLeft: '32%'}}>Creating invoices...</label>
                            </div>
                        </div>: ''
                    }
                    </DialogContent>
                    <DialogActions>
                    {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ?
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={handleClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>:
                        <>
                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={createInvoices}>
                                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                                <div class="fill-container"></div>
                            </button>
                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={handleClose}>
                                <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                                <div class="fill-container"></div>
                            </button>
                        </>}
                    
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default DebtorsSummary;