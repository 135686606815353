const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'sk_no',
        headerName: 'Account No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'station',
        headerName: 'Station',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'hours',
        headerName: 'Hours',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'hourly_rate',
        headerName: 'Hourly Rate',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'vat_registered',
        headerName: 'Vat Registered',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'remittance_value',
        headerName: 'Remitance Value',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'fleet_total',
        headerName: 'Fleet Total',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'sb_sub',
        headerName: 'SB-Sub Total',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'sb_vat',
        headerName: 'SB-VAT',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'sb_total',
        headerName: 'SB-Total',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
]
export default columns;