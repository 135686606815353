import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import importPdf from './img/importPdf.png'
import CircularProgress from '@mui/material/CircularProgress';


const Company = () =>{
    const user = AuthService.getCurrentUser();
    console.log(user)
    const [ utr, setUtr ] = useState()
    const [ companyName, setCompanyName ] = useState()
    const [ compReg, setCompReg ] = useState()
    const [ vat, setVat ] = useState()
    const [ checked, setChecked ] = useState(false)
    const [ check, setCheck ] = useState(false)
    const [ confMessage, setConfMEssage ] = useState("")
    const [ subOpen, setSubOpen ] = useState(false)
    const [ fileName, setFileName ] = useState()
    const [ file, setFile ] = useState()
    const [ warningFile, setWarningFile ] = useState("")
    const [ checkSubmit, setCheckSubmit ] = useState(false)

    useEffect(()=>{
        let name = {name: user.username}
        UserService.getDashData(name).then(
            response =>{
                let data = response.data
                setUtr(data.active_utr)
                setCompanyName(data.active_co_name)
                setCompReg(data.active_co_reg)
                setVat(data.active_co_vat)
            }
        ).catch(err => console.log(err))
    },[])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = () =>{
       setSubOpen(true)
    }

    const handleImport = (e, name) =>{
        setFileName(e.target.files[0]?.name)
        setFile(e.target.files[0])
    }

    const submitFile = () =>{
        if(file){
            setCheckSubmit(true)
            const formData = new FormData()
            formData.append('fileName', fileName)
            formData.append('file', file)
            formData.append('ticket_id', new Date().getTime())
            formData.append('username', user.username)
            formData.append('department', user.department)
            formData.append('to_department', 'Senior Management')
            formData.append('category', 'Vat certificate')
            formData.append('notes', 'Please check my vat certificate.')
            formData.append('status', 'Open')
            

            UserService.importVat(formData).then(
                response =>{
                    let data = {name: user.username, active_utr: utr, active_co_name: companyName, active_co_reg: compReg, active_co_vat: vat}
                    UserService.updatePDetails(data).then(
                        response =>{
                            setCheck(true)
                            setConfMEssage(<h3 style={{color: 'green'}}>Company details updated!</h3>)
                            setSubOpen(false)
                            setCheckSubmit(false)
                            sleep(3000).then(()=>{
                                setCheck(false)  
                                setFileName("")
                                setFile("")
                            })
                        }
                    ).catch(err => {
                        console.log(err)
                        setConfMEssage(<h3 style={{color: 'red'}}>Something went wrong, please refresh and try again!</h3>)
                    })
                }
            ).catch(err => console.log(err))
        }else{
            setWarningFile("please chose file")
        } 
    }

    useEffect(()=>{
        if(file){
            setWarningFile("")
        }
    },[file])

    return(
        <>
            {!check ?
                <>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">UTR No <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                                <input type="text" className="lonePm mobileInput" value={utr ? utr : ''} onChange={e => setUtr(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <h3 className="h3Cap"><b>Company Details (If Applicable)</b></h3>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">Company Name </label>
                                <input type="text" className="lonePm mobileInput" value={companyName ? companyName : ''} onChange={e => setCompanyName(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">Company Registration No </label>
                                <input type="text" className="lonePm mobileInput" value={compReg ? compReg : ''} onChange={e => setCompReg(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flexComp">
                                <label  className="label2">VAT No </label>
                                <input type="text" className="lonePm mobileInput" value={vat ? vat : ''} onChange={e => setVat(e.target.value)} ></input>
                                {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                        </div>
                    </div>
                    <p className="pClass" style={{marginLeft: '10px'}}>applicable if your VAT Registered</p>
                    <div className="flex" style={{marginTop: '20px'}}>
                        <div className="flexComp">
                                <label  className="label2">Confirmation <span style={{color: '#b30000', fontSize: '15px', fontStyle: 'italic'}}>* </span></label>
                                <div className="flex mobileInputFlex">
                                    <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" onChange={e => setChecked(e.target.checked)}/>
                                    <label for="vehicle3" style={{marginLeft: '5px', color: 'gray'}}> I confirm the details entered are correct</label>
                                    {/*warningWeek ? <p className="warning">{warningWeek}</p> : ''*/}
                                </div>
                        </div>
                    </div>
                    <div className="lastDiv">
                        <p className="pClass">You must check and confirm the details are correct before you can submit them.</p>
                        <p className="pClass">All entries are made at your own risk, Skilled Solutions hold no liability for any errors that occur as a result of incorrect information</p>
                    </div>
                    { checked ?
                    <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                    {/*</button><button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: '#2596be'}} onClick={handleSubmit}>
                        <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                        <div class="fill-container"></div>
                    </button>*/}
                    </button> 
                    : ''}
                </>: <>{confMessage}</>
            }
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={subOpen}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Please upload vat certificate below.</DialogTitle>
                    <DialogContent>
                        {fileName}
                    <div>
                        <input
                            accept="file/*"
                            //className={classes.input}
                            style={{display: 'none'}}
                            id="icon-button-file"
                            type="file"
                            //capture="environment"
                            onChange={(e) => handleImport(e, user.username)}
                        />
                        <label htmlFor="icon-button-file">
                            <img src={importPdf}/>
                            <label className="label2 " style={{marginLeft: '15px'}}>Chose File</label>
                        </label>
                    </div>
                    {warningFile ? 
                        <p className="pClass pCorrection topC" style={{ color: '#ff3333', textAlign: 'left', marginTop: '1%'}}>{warningFile}</p> : ''
                    }
                    </DialogContent>
                    <DialogActions>
                        {!checkSubmit ?
                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={submitFile}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Submit</span>
                                <div class="fill-container"></div>
                            </button>:
                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'gray', color:'white'}}>
                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}><CircularProgress color="inherit" />Please wait</span>
                                <div class="fill-container"></div>
                            </button>
                        }
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setSubOpen(false)}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
}
export default Company;