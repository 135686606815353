import React from "react";
import { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import columns from "./paymentSum";
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Select from 'react-select';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';



const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'medium', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
  menu: base => ({
      ...base,
      zIndex: 100,
      width: '101%',
      paddingLeft:'1%',
      marginLeft:'0%',
      
  }), 
}
Date.prototype.getWeek = function () {
  var target  = new Date(this.valueOf());
  var dayNr   = (this.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);
  var firstThursday = target.valueOf();
  target.setMonth(0, 1);
  if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - target) / 604800000);
}
function getDateRangeOfWeek(weekNo, year){
  console.log(year)
  var d1  = ''
  if(year !== new Date().getFullYear()){
    d1 = new Date(year, '01', '01');
  }else{
    d1 = new Date();
  }
  
  let numOfdaysPastSinceLastMonday = eval(d1.getDay());
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  var weekNoToday = d1.getWeek();
  var weeksInTheFuture = eval( weekNo - weekNoToday-1 );
  d1.setDate(d1.getDate() + eval( 7 * weeksInTheFuture ));
  var rangeIsFrom =  d1.getDate().toString().padStart(2, '0')+"/" +eval(d1.getMonth()+1).toString().padStart(2, '0') + "/" + d1.getFullYear();
  d1.setDate(d1.getDate() + 6);
  var rangeIsTo =  d1.getDate().toString().padStart(2, '0')+"/" + eval(d1.getMonth()+1).toString().padStart(2, '0') + "/" + d1.getFullYear() ;
  return rangeIsFrom + " - "+rangeIsTo;
};

const columnsInv = [
  { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
  {
      field: 'sk_no',
      headerName: 'Account No',
      headerClassName: 'table-header',
      width: 150,
      editable: false,
  },
  {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'table-header',
      width: 200,
      editable: false,
  },
  {
      field: 'station',
      headerName: 'Station',
      headerClassName: 'table-header',
      width: 160,
      editable: false,
  }
]

const PaymentSummary = () =>{
    const [ displayData, setDisplayData ] = useState([])
    const [ weeksOption, setWeeksOption ] = useState([])
    const [ weekData, setWeekData ] = useState([])
    const [ sumAss, setSumAss ] = useState([])
    const [ short, setShort ] = useState([])
    const [ hide, setHide ] = useState()
    const [ period, setPeriod ] = useState()
    const [ week, setWeek ] = useState('Select...')
    const [ due, setDue ] = useState()
    const [ selectedIds, setSelectedIds ] = useState([])
    const [ openInv, setOpenInv ] = useState(false)
    const [ agreeRows, setAgreeRows ] = useState([])
    const [ invoiceMessage, setInvoiceMessage ] = useState("")
    const [ invoiceCheck, setInvoiceCheck ] = useState(false)
    const [ totalRemittance, setTotalRemittance ] = useState()

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          {selectedIds.length > 0 && week !== 'Select...'? 
            <Button className="incGridButton" onClick={e => handleOpen(e)}>
              <NoteAddOutlinedIcon fontSize="small" />INVOICING
            </Button> :
            <Button className="incGridButton" style={{color: 'gray'}}>
              <NoteAddOutlinedIcon fontSize="small" />INVOICING
            </Button>
          }
          <GridToolbarQuickFilter />
          <p className="pInsideTable">Week:</p>
          <p className="pInsideTable">
            <Select 
                options={weeksOption}
                styles={colourStyles}
                value={{label: week, value: week}}
                onChange={e => handleWeek(e.value)}
            />
          </p>
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          {period ? <><p className="pInsideTable">Period:</p>
          <p className="pInsideTable">
            {period}
          </p></> : ''}
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          {due ? <><p className="pInsideTable">Due:</p>
          <p className="pInsideTable">
            {due}
          </p></> : ''}
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          <p className="pInsideTable"></p>
          {totalRemittance ? <><p className="pInsideTable">Total Remittance:</p>
          <p className="pInsideTable">
            {'£'+Math.round(totalRemittance*100)/100}
          </p></> : ''}
        </GridToolbarContainer>
      );
    }

    useEffect(()=>{
      setShort([])
      UserService.getPaymentSumAssociates().then(
        response =>{
          const data = response.data
          setSumAss(data)
          let display = []
          data.map((row, index) =>{
            display.push({id: index, sk_no: row.account_no, name: row.name, station: row.station, vat_registered: row.active_co_vat})
          })
          setDisplayData(display)
          console.log(response.data)
        }
      ).catch(err => console.log(err))

      UserService.getPaymentWeeks().then(
        response => {
          const data = response.data
          let weeks = []
          let option = []
          data.map(row =>{
            if(row.week_no){
               weeks.push(row.week_no)
            }
          })
          weeks = [...new Set(weeks)]
          weeks.sort((a, b) =>{
            return Number(a.split('-')[0]+a.split('-')[1])-Number(b.split('-')[0]+b.split('-')[1])
          })
          weeks.forEach(week =>{
            option.push({value: week, label: week})
          })
          setWeeksOption(option)
        }
      ).catch(err => console.log(err))

      UserService.getRateCard().then(
        response =>{
          const data = response.data
          let plc = []
          let hid = {id: false}
          data.map(row =>{
            if(row.schedule_shortcode){
              plc.push(row.schedule_shortcode)
              hid[row.schedule_shortcode] = false
            }
          })
          console.log(hid)
          setHide(hid)
          plc = [...new Set(plc)]
          let placeholdC = columns
          plc?.map(sh =>{
            placeholdC.push({
              field: sh,
              headerName: sh,
              headerClassName: 'table-header',
              width: 200,
              editable: false,
            })
          })
          
          setShort(placeholdC)
          console.log(placeholdC)
        }
      ).catch(err => console.log(err))
    },[])

    useEffect(()=>{
      
      console.log(short)
    },[short])

    const handleWeek = (value) =>{
      setWeek(value)
      if(value.length == 7){      
        let data = {week: value}
        UserService.getPaymentSummary(data).then(
          response =>{
            console.log(response.data)
            setWeekData(response.data)
          }
        ).catch(err => console.log(err))
        console.log(value)
        setPeriod(getDateRangeOfWeek(value.split('-')[1], value.split('-')[0]))
      }
    }

    useEffect(() =>{
      if(period){
        const lastDate = period.split(' - ')[1]
        const lastTime = new Date(lastDate.split('/')[2], Number(lastDate.split('/')[1])-1, lastDate.split('/')[0]).getTime()
        setDue(new Date(lastTime+(2*604800000)).getDate().toString().padStart(2, '0')+'/'+(new Date(lastTime+(2*604800000)).getMonth()+1).toString().padStart(2, '0')+ '/' +new Date(lastTime+(2*604800000)).getFullYear())
      }
    },[period])

    useEffect(() =>{
      let totalR = 0
      if(weekData.length > 0){
        let data = []
        sumAss?.map((row, index) =>{
          let dataRet = {}
          dataRet.hours = 0
          dataRet.fleet_total = 0
          dataRet.sb_sub = 0
          dataRet.sb_total = 0
          dataRet.sb_vat = 0
          weekData?.map(dataW =>{
            if(row.account_no.toLowerCase() == dataW.sk_no.toLowerCase()){
                dataRet.hours += dataW.duration
              if(dataW.category.match('FL')){
                dataRet.fleet_total = Number(dataRet.fleet_total+dataW.rate*dataW.qty)
              }else{
                dataRet.sb_sub += dataW.rate*dataW.qty
              }
              if(!dataRet[dataW.category]){
                dataRet[dataW.category] = 0
              }
              dataRet[dataW.category] += dataW.rate*dataW.qty
              
            }
          })
          if(row.active_co_vat){
            dataRet.sb_vat = dataRet.sb_sub*20/100
          }
          dataRet.sb_total = dataRet.sb_sub+dataRet.sb_vat
          dataRet.remittance_value = eval(Number(dataRet.sb_total)+Number(dataRet.fleet_total))
          dataRet.hourly_rate = dataRet.hours ? '£'+Number(dataRet.sb_total/dataRet.hours).toFixed(2) : 0
          dataRet.id = index
          dataRet.sk_no = row.account_no
          dataRet.name = row.name
          dataRet.station = row.station
          dataRet.vat_registered = row.active_co_vat
          if(dataRet.remittance_value > 0){
            totalR += dataRet.remittance_value
          }
          data.push(dataRet)
        })  
        data.map(row =>{
          const keys = Object.keys(row)
          keys.map(key =>{
            if(key !== 'sk_no' && key !== 'name' && key !== 'station' && key !== 'hours' && key !== 'hourly_rate' && key !== 'vat_registered'){
              row[key] = row[key] < 0 ? '-£'+row[key].toFixed(2).toString().substring(1, row[key].toFixed(2).toString().length) : '£'+row[key].toFixed(2)
            }
          })
        })
        
        setTotalRemittance(totalR)
        setDisplayData(data)  
      }
      console.log(totalR)
    }, [weekData])

    const handleClose = () =>{
      setOpenInv(false)
      setInvoiceMessage('')
    } 

    const handleOpen = (e) =>{
      let data = []
      selectedIds?.forEach(item =>{
        displayData?.map((row, index) =>{
          if(item == row.id){
            console.log(row)
            data.push({id: index, sk_no: row.sk_no , name: row.name, station: row.station})
          }
        })
      })
      setAgreeRows(data)
      setOpenInv(true)
    }

    const createInvoices = () =>{ 
      setInvoiceCheck(true)
      let invoiceData = [] 
      agreeRows?.map(row =>{
        weekData.map(item =>{
          if(row.name == item.associate_name){
              console.log(row)
              invoiceData.push(item)
          }
        })
      })
      console.log(invoiceData)
      UserService.processInvoices(invoiceData).then(
          response =>{
            if(response.data == 'ok'){
              setInvoiceMessage('Invoices created.')
              console.log(response)
              setInvoiceCheck(false)
            }else{
              setInvoiceMessage('Something went wrong.')
              setInvoiceCheck(false)
            }
          }
      ).catch(err => {
          setInvoiceMessage('Something went wrong.')
          console.log(err)
          setInvoiceCheck(false)
      })
    }

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Payment summary</h3>
            <Box sx={{ minHeight: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    checkboxSelection
                    rows={displayData}
                    columns={short}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    //loading={loading}
                    {...displayData}
                    onRowSelectionModelChange={params => setSelectedIds(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    onColumnVisibilityModelChange={param => setHide(param)}    
                    columnVisibilityModel={hide}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                />
            </Box> 
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openInv}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >{invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ? '' : 'Are you sure you want to create invoices for the selected lines?'}</label></DialogTitle>
                    <DialogContent>
                    {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ? 
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlNo" className="label2">{invoiceMessage}</label>
                            </div>
                        </div> :
                        <>
                          {invoiceCheck ?
                            <div className="flex spaceing" style={{marginTop: '60px'}}>
                                <div className="flexComp">
                                    <CircularProgress color="inherit" style={{marginLeft: '45%'}}/>
                                    <label for="dlNo" className="label2" style={{marginLeft: '32%'}}>Creating invoices...</label>
                                </div>
                            </div>: ''
                          }
                          <Box sx={{ height: '250px', minWidth: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                              <StripedDataGridPro
                                  rows={agreeRows}
                                  columns={columnsInv}
                                  initialState={{
                                      pagination: {
                                      paginationModel: {
                                          pageSize: 100,
                                      },
                                      },
                                  }}
                                  sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                                      backgroundColor: '#D35858',
                                      color: '#1a3e72',
                                  },
                                  [`.${gridClasses.cell}.hot`]: {
                                      backgroundColor: '#A6D358',
                                      color: '#1a3e72',
                                  },}}
                                  pageSizeOptions={[100]}
                                  slots={{
                                      loadingOverlay: LinearProgress,
                                  }}
                                  //loading={loading}
                                  {...displayData}
                                  onRowSelectionModelChange={params => setSelectedIds(params)}
                                  getRowClassName={(params) =>
                                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                      }
                                  onColumnVisibilityModelChange={param => setHide(param)}    
                                  columnVisibilityModel={hide}
                                  getCellClassName={(params) => {
                                      if (params.field === 'city' || params.value == null) {
                                      return '';
                                      }
                                      return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                                  }}
                              />
                          </Box> 
                        </>
                      }
                    </DialogContent>
                    <DialogActions>
                    {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ?
                      <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={handleClose}>
                          <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                          <div class="fill-container"></div>
                      </button>:
                      <>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={createInvoices}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={handleClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                      </> 
                    }
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default PaymentSummary;