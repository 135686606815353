const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'sko_no',
        headerName: 'SK No',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'last_payment',
        headerName: 'Last Payment',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'debt_start',
        headerName: 'Debt @ Start',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'payments_count',
        headerName: 'Payments Made',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'total_payments',
        headerName: 'Total Payments Made',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
    {
        field: 'balance',
        headerName: 'Balance',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
    },
]
export default columns;