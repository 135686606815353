import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled } from '@mui/material/styles';
import columns from "./performance";
import Definitions from "./definitions.component";
import { colors } from "@material-ui/core";

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
     
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    [`& .${gridClasses.cell}.fantasticPlus`]: {
        backgroundColor: '#389bf2',
        color: 'white',
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
        }
    }
}));
export function CustomFooterStatusComponent(props) {
    return (
      <>
      </>
    );
}


const PerformanceData = () =>{
    const user = AuthService.getCurrentUser()
    const [ performanceData, setPerformanceData ] = useState()
    const [ dataRes, setDataRes ] = useState([])
    const [ content, setContent ] = useState([])
    const [ currentWeek, setCurrentWeek ] = useState()
    const keysPlc = {delivered: 'Delivered', dcr: 'DCR', dnr_dpmo: 'DNR DPMO', pod: 'POD', cc: 'CC', ce: 'CE', dex: 'DEX', concessions: 'Concessions'}
    
    useEffect(()=>{
        UserService.getDashPerformance({name: user.username}).then(
            response =>{    
                console.log(response)
                setDataRes(response.data)
            }
        ).catch(err => console.log(err))
        setCurrentWeek(new Date().getWeek())
    },[])

    useEffect(()=>{
        let yearWeek = []
        let years = []
        let yearsComp = []
        dataRes?.map(row =>{
            yearWeek.push(row.week)
            years.push(row.week.split('-')[0])
        })
       
        years = years.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        })
        years.sort()
        years.reverse()
        yearWeek.sort()
        yearWeek.reverse()
        console.log(yearWeek)
        dataRes?.sort((a,b) => a.week.localeCompare(b.week))
        dataRes.reverse()
        yearWeek = yearWeek.filter((item, index) => {
            return yearWeek.indexOf(item) == index;
        })
        console.log(yearWeek)
        years?.forEach(item =>{
            let yearWeekComp = []
            yearWeek.forEach(weey =>{
                let accPlc = [] 
                let total = 0
                    dataRes?.map((row, i) =>{
                        if(row.week == weey && row.week.split('-')[0] == item){
                            const keys = Object.keys(row)
                            keys?.map((key, index) =>{
                                if(key !== 'amzl_id' && key !== 'createdAt' && key !== 'entry' && key !== 'name' && key !== 'station' && key !== 'updatedAt' && key !== 'week'){
                                    accPlc.push({id: i.toString()+index, metric: keysPlc[key], value: key == 'dcr' || key == 'pod' || key == 'cc' || key == 'dex' ? row[key]+'%' : row[key]})
                                }
                            })
                        }  
                    })  
                       
                    if(weey.split('-')[0] == item){
                        yearWeekComp.push(  <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Week {weey.split('-')[1]}{/*(currentWeek - Number(weey.split('-')[1])) < 3  && Number(weey.split('-')[0]) == new Date().getFullYear() ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : Number(weey.split('-')[0]) < new Date().getFullYear() && Number(weey.split('-')[1])-currentWeek > 49 ? <><AdjustOutlinedIcon style={{color: '#eb7a3e', marginLeft: '10%'}}/>  Outstanding</> : <><BlurCircularOutlinedIcon style={{color: '#33cc33', marginLeft: '10%'}}/>  Paid</>*/}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '-10%', marginTop:2 }}>
                                    <StripedDataGridPro
                                        rows={accPlc}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 100,
                                                },
                                            },
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false,
                                                },
                                            },
                                        }}
                                        sx={{fontSize: '9px', fontFamily: ''}}
                                        pageSizeOptions={[100]}
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            footer: CustomFooterStatusComponent
                                        }}
                                        
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        disableColumnResize={true}
                                        getCellClassName={(params) => {
                                            if(params.row.metric == 'DCR' && params.field == 'value' && Number(params.value?.split('%')[0]) >= 98){
                                                console.log(Number(params.value?.split('%')[0]))
                                            }
                                            if (
                                              params.field === 'metric' || params.field === 'target'
                                              //isAutogeneratedRow(params.row)
                                            ) {
                                              return '';
                                            }
                                            return (params.row.metric == 'DCR' || params.row.metric == 'POD' || params.row.metric == 'CC' || params.row.metric == 'DEX') && params.field == 'value' && Number(params.value?.split('%')[0]) >= 98   ? 'fantasticPlus' : 'cold';
                                          }}
                                    />
                                </Box>
                               
                            </AccordionDetails>
                        </Accordion>)
                    }
            })
            
            
            yearsComp.push( <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    {item}
                </AccordionSummary>
                <AccordionDetails>
                    {yearWeekComp}
                </AccordionDetails>
            </Accordion>)
            
        })
        yearsComp.push(
            <Accordion style={{backgroundColor: '#f0ca7f'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    Definitions
                </AccordionSummary>
                <AccordionDetails>
                    <Definitions />
                </AccordionDetails>
            </Accordion>
        )
        setContent(yearsComp)
        },[dataRes])

    return(
        <div>
            {content}
        </div>
    )
}
export default PerformanceData;