import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import pdfImg from './img/pdfIcon.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';



const DriversDocu = () =>{
    const user = AuthService.getCurrentUser()
    const [ details, setDetails ] = useState()
    const [ files, setFiles ] = useState([])
    const [ openPdf, setOpenPdf ] = useState(false)
    const [ pdfName, setPdfName ] = useState()
    const [ name, setName ] = useState()
    const [ fileURL, setFileUrl ] = useState()

    useEffect(() =>{
        //setOpen(true)
        setFiles([])
        UserService.getProfileData({name: user.username}).then(
            response =>{
                setDetails(response.data)
                console.log(response.data)
            }
        ).catch(err => console.log(err))
        let name = {name : user.username ? user.username : ''}
        setName(name)
        UserService.getFiles(name).then(
            response => {
                let data = response.data
                console.log(data)
                data?.map(element =>{
                    setFiles(oldFiles => [...oldFiles, 
                        <button className="icon"  onClick={(e) => handleOpenPfd(e, element)} style={{borderRadius: '5px', border: '1px solid gray', width: '48%' , minHeight: 'fit-content', marginLeft:'2%', paddingTop: '2%'}}>
                            <img src={pdfImg} className="iconImage"/>
                            <p>{element.split('.')[0]}</p>
                        </button>
                    ])
                })
            }
        ).catch(err => console.log(err))
        console.log(files)
    },[])

    const handleOpenPfd = (e, element) =>{
        setOpenPdf(true)
        setPdfName(element)
    }

    const handleShowClosePdf = () =>{
        setOpenPdf(false)
    }

    useEffect(()=>{
        if(pdfName && name){
            console.log(pdfName)
            UserService.getDocuments({name: user.username, pdfName: pdfName}).then(
                response =>{
                    console.log(response.data)
                    const file = new Blob([response.data], {
                        type: "application/pdf"
                      });
                      //Build a URL from the file
                      setFileUrl(URL.createObjectURL(file));
                      console.log(fileURL)
                }
            ).catch(error => {
                console.log(error);
            });
        }
    },[pdfName])

    return(
        <div className="dashArch">
            <div className="flex contIcons">
                {files}
            </div>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openPdf}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{pdfName}</DialogTitle>
                    <DialogContent>
                        <iframe src={fileURL} style={{width: '100%', height: '100%'}}/>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleShowClosePdf}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default DriversDocu;