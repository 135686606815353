import React from "react";

const Definitions = () =>{

    return(
        <ul>
            <li><b>Delivery Completion Rate (DCR)</b></li>
            <p>The share of packages dispatched to the drivers which are delivered to the customer (and not returned to the station). Thresholds are set at the station level to account for regional
            differences. A DSP who has Fantastic standing would receive a &gt;= 99.0999999999999% in DCR.</p>
            <li><b>Delivered Not Received DPMO (DNR DPMO)</b></li>	
            <p>The number of packages delivered but not received by customer, on per million opportunities / delivered (DPMO) basis. Thresholds are set at the station level to account for regional
            differences. A DSP who has Fantastic standing would receive a &lt;= 1500 in DNR DPMO.</p>
            <li><b>Photo on Delivery (POD)</b></li>
            <p>The number of usable (i.e. presentable to the customer) POD (Photo-on-delivery) photos taken, divided by total POD opportunities. A DSP who has Fantastic standing would receive
            a &gt;=98.5% in Photo on Delivery.</p>
            <li><b>Contact Compliance (CC)</b></li>
            <p>The metric is calculated as the count of all calls and texts that were made by a driver through the Amazon Flex app vs the total packages delivered with a call or text, as well as
            packages not delivered due to reasons such as Unable to Access (UTA), Unable to Locate (UTL), No Safe Locations (NSL). A DSP who has Fantastic standing would receive a 98%
            in Contact Compliance.</p>
            <li><b>Customer Delivery Feedback (DEX)</b></li>
            <p>CDF metric is a measurement of the customer feedback received after packages are delivered by the Delivery Associates (DAs). The metric is a weighted ratio that accounts for
            positive, negative, and no feedback. A DSP who has Fantastic standing would receive a &gt;= 84.5% in CDF.</p>
            <li><b>Customer Escalations DPMO (CE)</b></li>
            <p>Customer Escalations DPMO: The frequency at which drivers incur customer escalations, on a per-million opportunities (DPMO) basis. Violations are triple-weighted, and Defects are
            single weighted. Only successfully resolved tickets with customer escalations are counted with a 4-week delay. For example, Week 45 Scorecard Escalation Defect DPMO is
            calculated by counting and weighting the feedback incurred by drivers in Week 41 and not overturned by appeals. A DSP who has Fantastic standing would receive a 0 in Customer
            Escalations DPMO.</p>
        </ul>
    )
}
export default Definitions;